// src/SearchBar.js

import React from "react";
import TextField from "@mui/material/TextField";

const SearchBar = ({ handleSearch }) => {
  const textFieldStyles = {
    root: {
      color: "white",     // Set the text color to white
      borderColor: "white", // Set the border color to white
    },
  };

  return (
    <div>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        onChange={(e) => handleSearch(e.target.value)}
        sx={{
            "& .MuiInputLabel-root": {color: 'white'},//styles the label
            '&:hover fieldset': {
                borderColor: 'white',
              },
            "& .MuiOutlinedInput-root": {
              "& > fieldset": { borderColor: "white" },
              '&:hover fieldset': {
                borderColor: 'white',
              },
            },
          }}
        InputProps={{ style: textFieldStyles.root }} // Apply custom styles to the input
        style={{ backgroundColor: "transparent"}}      // Make the background transparent
      />
    </div>
  );
};

export default SearchBar;
