import './App.css';
import { Routes ,BrowserRouter as Router, Route } from 'react-router-dom';
import PodList from './components/PodList';
import ItemPage from './components/ItemPage';
import TextsmsIcon from '@mui/icons-material/Textsms';
import MailIcon from '@mui/icons-material/Mail';
import Button from "@mui/material/Button";
function App() {
  return (
    <div className="App">
      <h1>Ramham Moving Sale</h1>
      <h2><Button    style={{
         margin: 1,
         padding: 3,
         backgroundColor: "white",
         color: '#282C45'
    }} onClick={event =>  window.location.href='sms:6178637347'}> <TextsmsIcon/>&nbsp;&nbsp;(617) 863-7347 </Button> 
             <Button    style={{
              margin: 1,
              padding: 3,
              backgroundColor: "white",
              color: '#282C45'
}} onClick={event =>  window.location.href='mailto:sale@ramham.com' } > <MailIcon /> &nbsp;sale@ramham.com </Button> 
</h2>
      <Router>
      <Routes>
          <Route path="/" element={<PodList/>}/>
          <Route path="/item/:id" element={<ItemPage/>}/>
        </Routes>
    </Router>
      
    </div>
  );
}

export default App;