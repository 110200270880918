// src/PodList.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import SearchBar from "./SearchBar";
import Grid from "@mui/material/Grid";
import TextsmsIcon from '@mui/icons-material/Textsms';
import MailIcon from '@mui/icons-material/Mail';


const PodList = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    axios
      .get("https://pod-api.ramham.com/table")
      .then((response) => {
        setItems(response.data);
        setFilteredItems(response.data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = items.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  return (
    <div>
      <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3081.471615808717!2d-71.29375809603646!3d43.057213121503544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e25be1653525fd%3A0x353425c42a32cd9b!2s22%20Old%20Candia%20Rd%2C%20Candia%2C%20NH%2003034!5e0!3m2!1sen!2sus!4v1695904605677!5m2!1sen!2sus" style={{border : '0'}} width="400" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      &nbsp; <img src="https://rhpodsalefuncso.blob.core.windows.net/images/house.jpeg" alt="Ramham Household" height="200" />
      <br/>
      <SearchBar handleSearch={handleSearch} />
      <br />
      <Grid container spacing={2}>
        {filteredItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <Card >
              <CardMedia
                component="img"
                alt={item.name}
                height="240"
                image={item.primary_image_url}
              />
              <CardContent>
              {item.sold ? (
        <img src="/assets/sold.png" width="100px" alt="Sold" /> 
      ) : null}
              <Typography color="textSecondary" variant="body2">
                  Category: {item.category}
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                <Link to={`/item/${item.id}`}>{item.name}</Link>
                </Typography>
             
               <Button    style={{
         margin: 1,
        backgroundColor: "#282C45",
        color: 'white'
    }} onClick={event =>  window.location.href='sms:6178637347&body=Hello! I\'m Interested in the ' + item.name }> <TextsmsIcon/>&nbsp;I'm Interested </Button> 
             
             
             <Button    style={{
              margin: 1,
  backgroundColor: "#282C45",
  color: 'white'
}} onClick={event =>  window.location.href='mailto:sale@ramham.com?subject=Hello! I\'m Interested in the ' + item.name } > <MailIcon /> &nbsp;I'm Interested </Button> 
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default PodList;





