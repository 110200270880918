import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Box from "@mui/material/Box"
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import TextsmsIcon from '@mui/icons-material/Textsms';
import MailIcon from '@mui/icons-material/Mail';
import Button from "@mui/material/Button";


const ItemPage = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);

  useEffect(() => {
    // Fetch item data from the API endpoint
    fetch(`https://pod-api.ramham.com/item/${id}`)
      .then((response) => response.json())
      .then((data) => setItem(data))
      .catch((error) => console.error(error));
  }, [id]);

  if (!item) {
    return <div>Loading...</div>;
  }

  return (
    <Box display="inline-block">
    <Card>
    <CardMedia
      component="img"
      alt={item.name}
      height="240"
      image={item.primary_image_url}
    />
    <CardContent>
    {item.sold ? (
        <img src="/assets/sold.png" width="100px" alt="Sold" /> 
      ) : null}
      <Typography gutterBottom variant="h5" component="div">
        {item.name}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        Category: {item.category}
      <p>Description: {item.description}</p>
      <p>Price: {item.price}</p>
      </Typography>
      <Button    style={{
         margin: 1,
        backgroundColor: "#282C45",
        color: 'white'
    }} onClick={event =>  window.location.href='sms:6178637347&body=Hello! I\'m Interested in the ' + item.name }> <TextsmsIcon/>&nbsp;I'm Interested </Button> 
             
             
             <Button    style={{
              margin: 1,
  backgroundColor: "#282C45",
  color: 'white'
}} onClick={event =>  window.location.href='mailto:sale@ramham.com?subject=Hello! I\'m Interested in the ' + item.name } > <MailIcon /> &nbsp;I'm Interested </Button> 
      <Carousel>
        {item.images.map((imageUrl, index) => (
          <div key={index} >
            <img src={imageUrl} alt=''/>
          </div>
        ))}
      </Carousel>
      <Link to="/">Back to Home</Link>
    </CardContent>
  </Card>
</Box>

  );
};

export default ItemPage;
